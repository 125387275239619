<template lang="pug">
Modal(v-model="isShow", :close="close" :options="options")
  .manual-payment-modal
    .info-container
      h1 {{ $t('.title') }}

      form(ref="form" @submit.prevent="confirm")
        .content
          .form-fields
            .first-section
              date-field(
                name="bill[payment_date]",
                :label="$t('.label.paymentDate')",
                :required="true",
                v-model="paymentDate"
              )

              date-field(
                name="bill[credit_date]",
                :label="$t('.label.creditDate')",
                :required="true",
                v-model="creditDate"
              )

              currency-field(
                name="bill[payment_value]",
                v-model="paymentValue",
                type="number",
                :placeholder="$t('.placeholder.paymentValue')",
                :label="$t('.label.paymentValue')",
                :required="true"
              )

              payment-type-field(
                name="bill[payment_type]",
                v-model="paymentType",
                :required="true"
              )

            .difference-container(v-if="paymentValue && difference")
              h2 {{ $t('.difference.title', { value: formatter(difference) }) }}

              label.difference-action
                input(
                  required
                  type="radio"
                  id="disconsider"
                  value="disconsider"
                  name="bill[difference_action]"
                )
                | {{ $t('.difference.actions.disconsider') }}

              label.difference-action
                input(
                  type="radio"
                  id="next_bill"
                  value="next_bill"
                  name="bill[difference_action]"
                )
                | {{ $t('.difference.actions.nextBill') }}

              label.difference-action(v-if="difference < 0")
                input(
                  type="radio"
                  id="new_bill"
                  value="new_bill"
                  name="bill[difference_action]"
                )
                | {{ $t('.difference.actions.newBill') }}

          .summary
            h2 {{ $t('.summary.title') }}

            .summary-content
              ul
                li
                  span {{ $t('.summary.value') }}
                  span {{ formatter(bill.value) }}

                li
                  span {{ $t('.summary.fine') }}
                  span {{ formatter(fine) }}

                li
                  span {{ $t('.summary.interest') }}
                  span {{ formatter(interest) }}

              hr

              ul
                li
                  span {{ $t('.summary.billValue') }}
                  span {{ formatter(billingValue) }}

                li
                  span {{ $t('.summary.paymentValue') }}
                  span {{ formatter(paymentValue) }}

        .buttons-container
          button.close(@click="close" :disabled="disabled")
            | {{ $t(".buttons.close") }}

          button.confirm(type="submit" :disabled="disabled")
            | {{ $t(".buttons.confirm") }}
</template>

<script>
import { ref } from 'vue'
import currencyFormatter from 'lib/currency-formatter'
import PaymentTypeField from './payment-type-field.vue'

export default {
  components: { PaymentTypeField },

  watch: {
    paymentDate(val) {
      if (!val) {
        this.fine = 0
        this.interest = 0

        return
      }

      const params = {
        payment_date: val
      }

      this.$http
        .post(`/admins/bills/${this.bill.id}/penalties-calculator`, params)
        .then((response) => {
          const data = response.data

          this.fine = data.fine_value
          this.interest = data.interest_value
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.penalties.failure"))
          this.close()
        })
    }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.management.bills.manualPayment",

      options: { closeClickDimmed: false },

      // data
      fine: 0,
      interest: 0,
      paymentDate: null,
      creditDate: null,
      paymentValue: null,
      paymentType: null,
      disabled: false
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    callback: { type: Function, required: true },
    show: { type: Boolean, default: false },
    bill: { type: Object, required: true }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  computed: {
    difference() {
      return this.paymentValue - this.billingValue
    },

    billingValue() {
      return parseFloat(this.bill.value) + this.fine + this.interest
    }
  },

  methods: {
    submitForm() {
      this.$refs.form.submit()
    },

    formatter(value) {
      return currencyFormatter.format(value)
    },

    close() {
      this.paymentDate = null

      this.$emit('close')
    },

    formData() {
      return {
        payment_date: this.paymentDate
      }
    },

    confirm() {
      this.disabled = true

      const formData = new FormData(this.$refs.form)

      formData.set('bill[payment_value]', this.paymentValue)

      this.$http
        .post(`/admins/bills/${this.bill.id}/manual-payment`, formData)
        .then(() => {
          this.close()
          this.callback()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.failure"))
        }).finally(() => {
          this.disabled = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.manual-payment-modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  width: 90%;

  @media (orientation: landscape) {
    width: 70%;
  }

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.5rem;

      .summary {
        border: 1px solid $grey-color-light;
        border-radius: 0.5rem;

        h2 {
          padding: 1rem;
          font-size: 1.5rem;
          border-radius: 0.5rem 0.5rem 0rem 0rem;
          border-bottom: 1px solid $grey-color-light;
          text-transform: uppercase;
          font-weight: 550;
          color: $second-color-light;
          background-color: $grey-color-high-light;
        }

        .summary-content {
          padding: 0 1rem;

          ul {
            list-style-type: none;

            li {
              display: flex;
              justify-content: space-between;

              span:first-child {
                font-weight: 550;
              }
            }
          }

          hr {
            width: 100%;
          }
        }
      }

      .form-fields {
        .first-section {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1rem;

          @media (orientation: portrait) {
            display: flex;
            flex-direction: column;
          }
        }

        .difference-container {
          background-color: $grey-color-high-light;
          border-radius: 0.5rem;
          padding: 1rem;
          margin-bottom: 1rem;

          h2 {
            font-size: 1.7rem;
            font-weight: 550;
            color: $second-color-light;
          }

          .difference-action {
            display: flex;
            gap: 1rem;
            margin-bottom: 1.5rem;

            input[type="radio"] {
              accent-color: $primary-color;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>
