<template lang="pug">
#page
  navbar
  .error-page-container.large-screen
    .image(:style="imageStyle")
    .infos
      h1(v-if="title") {{ title }}
      h2 {{ subtitle }}
      p {{ message }}
      router-link.button.button-primary(
        :to="{ name: toRoute }"
      )
        | {{ buttonMessage || $t('.button') }}

  .error-page-container.small-screen
    .infos
      h1(v-if="title") {{ title }}
      h2 {{ subtitle }}
      p {{ message }}
    .image(:style="imageStyle")
    .button-container
      router-link.button.button-white.button-primary-outline(
        :to="{ name: toRoute }"
      )
        | {{ buttonMessage || $t('.button') }}
</template>

<script>
import browserType from '@/modules/browser_type'
import vueAuthenticate from "lib/vue-authenticate"
import Navbar from "./navbar.vue"

export default {
  mixins: [vueAuthenticate],

  components: { Navbar },

  data() {
    return {
      defaultI18nScope: "staticPages.components.errorPage"
    }
  },

  props: {
    title: { type: String },
    subtitle: { type: String, default: '-' },
    message: { type: String, default: '-' },
    backgroundImage: { type: String },
    buttonRoute: { type: String },
    buttonMessage: { type: String }
  },

  computed: {
    imageStyle() {
      if (!this.backgroundImage) return {}

      const url = require(`@/assets/images/${this.backgroundImage}`)

      return { backgroundImage: `url(${url})`}
    },

    toRoute() {
      if (this.buttonRoute) return this.buttonRoute

      if (!this.$user.get) return "home"

      if (this.camelizedRole == 'admin') {
        return browserType.isDesktop()
          ? 'adminUrlChanged'
          : 'adminManagementIndex'
      }

      return `realEstateManagementIndex`
    }
  }
}
</script>

<style scoped lang="scss">
.error-page-container {
  background: $grey-color-high-light;
  height: 100vh;
  overflow-x: hidden;

  .image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    margin-left: -2rem;
  }

  .infos {
    font-family: "Open Sans";
    color: $second-color;

    h1 {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    h2 {
      font-weight: 700;
      margin-bottom: 2rem;
    }

    p {
      color: $second-color-light;
      font-weight: 500;
      margin-bottom: 2rem;
      white-space: pre-line;
    }

    .button {
      width: 100%;
    }
  }

  &.large-screen {
    display: none;
    align-items: center;
    justify-items: left;
    grid-template-columns: repeat(2, 1fr);
    justify-items: left;
    padding-block: 7rem;
    gap: 5rem;

    .image {
      width: calc(100% + 2rem);
    }

    .infos {
      width: 75%;
      max-width: 60rem;
    }

    h1 {
      font-size: 8rem !important;
    }

    h2 {
      font-size: 3rem !important;
    }

    p {
      font-size: 1.7rem !important;
    }
  }

  &.small-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    padding-top: 10rem;

    .image {
      min-height: 5em;
      max-height: 15em;
      width: calc(100% + 4rem);
    }

    .infos {
      width: 75%;
      max-width: 50rem;

      h1 {
        font-size: 6rem !important;
      }

      h2 {
        font-size: 2.5rem !important;
      }

      p {
        font-size: 1.5rem !important;
      }
    }

    .button-container {
      position: relative;
      background-color: $primary-color;
      display: flex;
      justify-content: center;
      gap: 1rem;
      width: 100%;
      height: 100%;
      padding-block: 5rem;
      border-radius: 0 1.25rem 0 0;

      &::before, &::after {
        content: '';
        position: absolute;
        left: 0;
      }

      &::before {
        background: $primary-color;
        top: -2.5rem;
        width: 2.5rem;
        height: 2.6rem;
      }

      &::after {
        background: $grey-color-high-light;
        top: -2.5rem;
        width: 2.6rem;
        height: 2.5rem;
        border-radius: 0 0 0 2.5rem;
      }

      .button {
        width: 75%;
        max-width: 50rem;
      }
    }
  }

  @media only screen and (min-width: 930px) {
    &.large-screen {
      display: grid !important;
    }

    &.small-screen {
      display: none !important;
    }
  }
}
</style>
